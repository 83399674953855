export default [
  {
    path: "/blog/list",
    name: "pages-blog-list",
    component: () => import("@/views/pages/blog/BlogList.vue"),
    meta: {
      pageTitle: "Blog List",
      breadcrumb: [
        {
          text: "Konten",
        },
        {
          text: "Blog",
        },
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/blog/create",
    name: "pages-blog-create",
    component: () => import("@/views/pages/blog/BlogCreate.vue"),
    meta: {
      pageTitle: "Tambah Blog",
      breadcrumb: [
        {
          text: "Konten",
        },
        {
          text: "Blog",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
    },
  },
];
