import axiosIns from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    blog: [],
  },
  mutations: {
    SET_BLOG(state, blog) {
      state.blog = blog;
    },
  },
  actions: {
    async createBlog({ commit }, blog) {
      const response = await axiosIns.post("/api/post", blog, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      commit("SET_BLOG", response.data.data.blog);
    },
  },
  getters: {
    blog(state) {
      return state.blog;
    },
  },
};
