import axiosIns from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    async fetchCategories({ commit }) {
      const response = await axiosIns.get("/api/category");
      commit("SET_CATEGORIES", response.data.data.categories);
    },
  },
  getters: {
    categories(state) {
      return state.categories;
    },
  },
};
